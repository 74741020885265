import React from 'react'
import { almostWhiteOnGray, white } from '../../styling/colors'
import { IconProps } from './types'

export const PaperPlane = ({
  width = 16,
  height = 16,
  stroke = almostWhiteOnGray,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7613 7.99987L2.03342 14.1281L4.39044 7.99987L2.03342 1.87161L14.7613 7.99987Z"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
