import React from 'react'
import { black } from '../../styling/colors'
import { IconProps } from './types'

interface Props extends IconProps {
  forButton?: boolean
}

export const Quote = ({ width = 29, height = 23, fill = black }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 29 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.599609V22.1996L10.875 11.3996V0.599609H0ZM18.125 0.599609V22.1996L29 11.3996V0.599609H18.125Z"
      fill={fill}
    />
  </svg>
)
