import * as React from "react";

function SvgBottle(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.071 29.625H13a2 2 0 01-2-2V17.552a8 8 0 011.095-4.04l.346-.591a8 8 0 001.095-4.04V4a1 1 0 011-1h1.535M16.071 29.625h3.072a2 2 0 002-2V17.552a8 8 0 00-1.095-4.04l-.346-.591a8 8 0 01-1.095-4.04V4a1 1 0 00-1-1h-1.536"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgBottle;
