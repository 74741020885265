import React from 'react'
import { white } from '../../styling/colors'
import { IconProps } from './types'

export const Color = ({
  width = 20,
  height = 20,
  stroke = white,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7.25" stroke={stroke} strokeWidth="1.5" />
  </svg>
)
