import * as React from "react";

function SvgFile(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.333 2.667H8a2.667 2.667 0 00-2.667 2.666v21.334A2.667 2.667 0 008 29.333h16a2.667 2.667 0 002.667-2.666V12l-9.334-9.333z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.333 2.667V12h9.334"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFile;
