import React from 'react'
import { black, white } from '../../styling/colors'
import { IconProps } from './types'

export const Arrow2Left = ({
  width = 20,
  height = 20,
  stroke = black,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8334 10L4.16675 10"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0001 15.8335L4.16675 10.0002L10.0001 4.16683"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
