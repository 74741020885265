import * as React from "react";

function SvgPackage(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12.533l-12-6.92M28 21.333V10.667a2.667 2.667 0 00-1.333-2.307l-9.334-5.333a2.667 2.667 0 00-2.666 0L5.333 8.36A2.667 2.667 0 004 10.667v10.666a2.666 2.666 0 001.333 2.307l9.334 5.333a2.667 2.667 0 002.666 0l9.334-5.333A2.667 2.667 0 0028 21.333z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.36 9.28L16 16.013 27.64 9.28M16 29.44V16"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgPackage;
