import React from 'react'
import { white } from '../../styling/colors'
import { IconProps } from './types'

export const FillingCAP = ({
  width = 20,
  height = 20,
  stroke = white,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9999 2.2417L14.7166 6.95837C15.6494 7.89054 16.2847 9.07842 16.5423 10.3717C16.7999 11.6651 16.6681 13.0057 16.1637 14.2242C15.6592 15.4426 14.8047 16.484 13.7083 17.2168C12.6119 17.9495 11.3228 18.3406 10.0041 18.3406C8.68534 18.3406 7.39624 17.9495 6.29982 17.2168C5.20339 16.484 4.34891 15.4426 3.84446 14.2242C3.34 13.0057 3.20823 11.6651 3.46582 10.3717C3.72341 9.07842 4.35878 7.89054 5.29157 6.95837L9.9999 2.2417Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
