import React from 'react'
import { white } from '../../styling/colors'
import { IconProps } from './types'

export const Weight = ({
  width = 20,
  height = 20,
  stroke = white,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 6.66675C11.3807 6.66675 12.5 5.54746 12.5 4.16675C12.5 2.78604 11.3807 1.66675 10 1.66675C8.61929 1.66675 7.5 2.78604 7.5 4.16675C7.5 5.54746 8.61929 6.66675 10 6.66675Z"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18.3334V6.66675"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.1665 10H1.6665C1.6665 12.2101 2.54448 14.3298 4.10728 15.8926C5.67008 17.4554 7.7897 18.3333 9.99984 18.3333C12.21 18.3333 14.3296 17.4554 15.8924 15.8926C17.4552 14.3298 18.3332 12.2101 18.3332 10H15.8332"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
