import React from 'react'
import { black, white } from '../../styling/colors'
import { IconProps } from './types'

export const Play = ({
  width = 7,
  height = 10,
  fill = black,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 1.86852C0 1.06982 0.890145 0.59343 1.5547 1.03647L6.25193 4.16795C6.84566 4.56377 6.84566 5.43623 6.25192 5.83205L1.5547 8.96353C0.890144 9.40657 0 8.93018 0 8.13148V1.86852Z"
      fill={fill}
    />
  </svg>
)
