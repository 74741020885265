import * as React from "react";

function SvgShield(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 29.333S26.667 24 26.667 16V6.667L16 2.667l-10.667 4V16C5.333 24 16 29.333 16 29.333z"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgShield;
