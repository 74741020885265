import React from 'react'
import { IconProps } from './types'

interface Props extends IconProps {
  forButton?: boolean
  className?: string
}

export const ArrowRight = ({
  width = 8,
  height = 14,
  stroke = '#FFFFFF',
  forButton = false,
  className,
}: Props) => (
  <svg
    width={forButton ? 14 : width}
    height={height}
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 0.999999L7 7L1 13"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
