import React from 'react'
import { IconProps } from './types'

export const Globe = ({
  width = 12,
  height = 12,
  fill = '#9E9E9E',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 1.5625C3.54924 1.5625 1.5625 3.54924 1.5625 6C1.5625 8.45076 3.54924 10.4375 6 10.4375C8.45076 10.4375 10.4375 8.45076 10.4375 6C10.4375 3.54924 8.45076 1.5625 6 1.5625ZM0.4375 6C0.4375 2.92792 2.92792 0.4375 6 0.4375C9.07208 0.4375 11.5625 2.92792 11.5625 6C11.5625 9.07208 9.07208 11.5625 6 11.5625C2.92792 11.5625 0.4375 9.07208 0.4375 6Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.4375 6C0.4375 5.68934 0.68934 5.4375 1 5.4375H11C11.3107 5.4375 11.5625 5.68934 11.5625 6C11.5625 6.31066 11.3107 6.5625 11 6.5625H1C0.68934 6.5625 0.4375 6.31066 0.4375 6Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.56263 5.99997C4.59628 7.49623 5.1024 8.93771 6 10.1219C6.8976 8.93771 7.40372 7.49623 7.43737 5.99997C7.40372 4.50371 6.8976 3.06222 6 1.87806C5.1024 3.06222 4.59628 4.50371 4.56263 5.99997ZM6 0.999968L5.58468 0.620605C4.24208 2.09046 3.47909 3.99795 3.43762 5.98825C3.43746 5.99606 3.43746 6.00388 3.43762 6.01168C3.47909 8.00199 4.24208 9.90948 5.58468 11.3793C5.69125 11.496 5.84198 11.5625 6 11.5625C6.15802 11.5625 6.30875 11.496 6.41532 11.3793C7.75792 9.90948 8.52091 8.00199 8.56238 6.01168C8.56254 6.00388 8.56254 5.99606 8.56238 5.98825C8.52091 3.99795 7.75792 2.09046 6.41532 0.620605L6 0.999968Z"
      fill={fill}
    />
  </svg>
)
