import React from 'react'
import { IconProps } from './types'

interface Props extends IconProps {
  forButton?: boolean
}

export const ArrowLeft = ({
  width = 8,
  height = 14,
  stroke = '#FFFFFF',
  forButton = false
}: Props) => (
  <svg
    width={forButton ? 14 : width}
    height={height}
    viewBox={forButton ? '-2 0 14 14' : '0 0 8 14'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13L1 7L7 1"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
