import React from 'react'
import { IconProps } from './types'

export const Circle = ({
  width = 12,
  height = 12,
  fill = '#FFFFFF',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6" r="6" fill={fill} />
  </svg>
)
