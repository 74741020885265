export enum When {
  Medium = '@media screen and (min-width: 769px)',
  Large = '@media screen and (min-width: 1125px)',
  MediumAndShort = '@media screen and (min-width: 768px) and (min-height: 790px)',
  MediumAndTall = '@media screen and (min-width: 768px) and (min-height: 920px)',
  LargeAndShort = '@media screen and (min-width: 1120px) and (min-height: 790px)',
  LargeAndTall = '@media screen and (min-width: 1120px) and (min-height: 620px)',
  Short = '@media screen and (min-height: 790px)',
  Tall = '@media screen and (min-height: 920px)',
}
