import React from 'react'
import { IconProps } from './types'

export const Logo = ({
  width = 64,
  height = 33,
  fill = '#FFFFFF',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 64 33"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3424 19.8317C40.8161 19.8317 40.3901 19.4056 40.3901 18.8794V3.37874C40.3901 2.56649 39.7282 1.9046 38.9159 1.9046C38.2187 1.9046 37.6275 2.37927 37.4772 3.06033L34.4021 16.9512C34.0262 18.6479 32.5506 19.8331 30.8126 19.8331C29.0745 19.8331 27.5989 18.6479 27.223 16.9512L24.1479 3.06033C23.9976 2.38075 23.405 1.9046 22.7092 1.9046C21.8969 1.9046 21.235 2.56649 21.235 3.37874V18.8808C21.235 19.4071 20.809 19.8331 20.2827 19.8331C19.7565 19.8331 19.3304 19.4071 19.3304 18.8808V3.37874C19.3304 1.51542 20.8459 0 22.7092 0C24.3057 0 25.6634 1.08939 26.0083 2.64904L29.0834 16.5399C29.2647 17.3581 29.9752 17.9286 30.814 17.9286C31.6513 17.9286 32.3634 17.3581 32.5447 16.5399L35.6197 2.64904C35.9647 1.08939 37.3209 0 38.9189 0C40.7822 0 42.2976 1.51542 42.2976 3.37874V18.8808C42.2947 19.4056 41.8686 19.8317 41.3424 19.8317Z"
      fill={fill}
    />
    <path
      d="M59.2695 19.7212H50.6133C48.0056 19.7212 45.8828 17.5999 45.8828 14.9907V4.8736C45.8828 2.26583 48.0041 0.143066 50.6133 0.143066H59.2695C61.8773 0.143066 64.0001 2.26436 64.0001 4.8736V5.68143C64.0001 6.2077 63.574 6.63373 63.0478 6.63373C62.5215 6.63373 62.0955 6.2077 62.0955 5.68143V4.8736C62.0955 3.31543 60.8277 2.04913 59.271 2.04913H50.6148C49.0566 2.04913 47.7904 3.3169 47.7904 4.8736V14.9907C47.7904 16.5488 49.0581 17.8151 50.6148 17.8151H59.271C60.8292 17.8151 62.0955 16.5474 62.0955 14.9907V14.2167C62.0955 13.6905 62.5215 13.2644 63.0478 13.2644C63.574 13.2644 64.0001 13.6905 64.0001 14.2167V14.9907C64.0001 17.5999 61.8788 19.7212 59.2695 19.7212Z"
      fill={fill}
    />
    <path
      d="M15.5096 17.6824H14.7622C12.6217 17.6824 10.5963 16.7507 9.2032 15.1262L8.40126 14.1916C7.08338 12.6541 5.16551 11.774 3.14151 11.774H1.90618V8.07832H3.14151C5.16551 8.07832 7.08338 7.19677 8.40126 5.66072L9.2032 4.72611C10.5963 3.1016 12.6217 2.16994 14.7622 2.16994H15.5096C16.0359 2.16994 16.4619 1.74391 16.4619 1.21765C16.4619 0.691375 16.0359 0.265349 15.5096 0.265349H14.7622C12.066 0.265349 9.51277 1.43876 7.75706 3.48635L6.95513 4.42096C5.99988 5.53542 4.60976 6.17519 3.14151 6.17519H1.90618V0.952296C1.90618 0.427501 1.48015 0 0.953883 0C0.427613 0 0.00158691 0.426027 0.00158691 0.952296V18.9192C0.00158691 19.444 0.427613 19.8715 0.953883 19.8715C1.48015 19.8715 1.90618 19.4454 1.90618 18.9192V13.6801H3.14151C4.60976 13.6801 5.99988 14.3198 6.95513 15.4343L7.75706 16.3689C9.51277 18.415 12.066 19.5899 14.7622 19.5899H15.5096C16.0359 19.5899 16.4619 19.1639 16.4619 18.6376C16.4619 18.1084 16.0359 17.6824 15.5096 17.6824Z"
      fill={fill}
    />
    <path
      d="M4.06127 29.2779H4.58017V31.6675C4.34136 31.924 4.05832 32.1215 3.73106 32.2586C3.4038 32.3957 3.06032 32.465 2.69916 32.465C2.18763 32.465 1.72622 32.3279 1.31641 32.0537C0.906598 31.7795 0.585236 31.3992 0.350847 30.9127C0.116458 30.4263 0 29.8823 0 29.2794C0 28.6765 0.116458 28.1325 0.350847 27.646C0.585236 27.1596 0.906598 26.7792 1.31641 26.505C1.72622 26.2309 2.1891 26.0938 2.70653 26.0938C3.09718 26.0938 3.45539 26.1704 3.78118 26.3222C4.10844 26.4741 4.38263 26.6996 4.60818 26.9974L4.27207 27.4175C3.86668 26.9281 3.35221 26.6834 2.72864 26.6834C2.31441 26.6834 1.93997 26.7954 1.60534 27.0195C1.27071 27.2436 1.00979 27.5532 0.819624 27.9497C0.629459 28.3462 0.53364 28.79 0.53364 29.2779C0.53364 29.7673 0.629459 30.2081 0.819624 30.6017C1.00979 30.9953 1.27071 31.3063 1.60239 31.5319C1.93408 31.7589 2.30704 31.8724 2.72127 31.8724C3.24754 31.8724 3.69421 31.7117 4.0598 31.3889V29.2779H4.06127Z"
      fill={fill}
    />
    <path
      d="M15.6908 26.1455H16.2318V31.8357H19.099V32.4077H15.6908V26.1455Z"
      fill={fill}
    />
    <path
      d="M32.7983 30.736H29.9458L29.3311 32.4091H28.7679L31.1089 26.1455H31.6425L33.9835 32.4091H33.413L32.7983 30.736ZM32.6007 30.1994L31.3713 26.8354L30.1418 30.1994H32.6007Z"
      fill={fill}
    />
    <path
      d="M45.3166 32.2395C44.9687 32.0906 44.699 31.8931 44.5088 31.6483L44.7211 31.1383C44.9068 31.3653 45.153 31.5481 45.4596 31.6881C45.7662 31.8282 46.0832 31.8989 46.4104 31.8989C46.8689 31.8989 47.2124 31.7958 47.4423 31.5909C47.6708 31.3845 47.7858 31.1177 47.7858 30.7904C47.7858 30.5398 47.7239 30.3408 47.5986 30.1904C47.4748 30.0415 47.3215 29.9265 47.1416 29.8455C46.9618 29.7644 46.7097 29.6774 46.3883 29.5816C46.0036 29.4622 45.6955 29.3472 45.467 29.2366C45.237 29.1261 45.041 28.958 44.8788 28.731C44.7152 28.504 44.6341 28.1974 44.6341 27.8097C44.6341 27.4942 44.7019 27.2053 44.839 26.9458C44.9761 26.6864 45.1854 26.4785 45.4685 26.3237C45.7515 26.1689 46.1023 26.0908 46.521 26.0908C46.8144 26.0908 47.1003 26.1395 47.3804 26.2382C47.6605 26.337 47.9037 26.4726 48.1086 26.6451L47.9259 27.1728C47.7106 27.0004 47.4821 26.8706 47.2389 26.7837C46.9957 26.6967 46.7554 26.6539 46.5225 26.6539C46.0743 26.6539 45.7367 26.7601 45.5097 26.9709C45.2827 27.1832 45.1692 27.4559 45.1692 27.789C45.1692 28.0396 45.2311 28.2416 45.3564 28.3934C45.4803 28.5453 45.6365 28.6617 45.8252 28.7428C46.0124 28.8239 46.2645 28.9109 46.5829 29.0067C46.9588 29.1202 47.2625 29.2322 47.4939 29.3428C47.7254 29.4533 47.9214 29.6199 48.0821 29.844C48.2428 30.0681 48.3239 30.3703 48.3239 30.7521C48.3239 31.069 48.2546 31.3565 48.116 31.6159C47.9775 31.8754 47.7637 32.0817 47.4762 32.2336C47.1888 32.3854 46.835 32.4621 46.4163 32.4621C46.0301 32.4635 45.666 32.3884 45.3166 32.2395Z"
      fill={fill}
    />
    <path
      d="M60.1288 32.2395C59.7809 32.0906 59.5111 31.8931 59.3209 31.6483L59.5332 31.1383C59.7189 31.3653 59.9651 31.5481 60.2717 31.6881C60.5784 31.8282 60.8953 31.8989 61.2226 31.8989C61.681 31.8989 62.0245 31.7958 62.2545 31.5909C62.483 31.3845 62.598 31.1177 62.598 30.7904C62.598 30.5398 62.536 30.3408 62.4107 30.1904C62.2869 30.0415 62.1336 29.9265 61.9538 29.8455C61.7739 29.7644 61.5218 29.6774 61.2005 29.5816C60.8157 29.4622 60.5076 29.3472 60.2791 29.2366C60.0492 29.1261 59.8531 28.958 59.6909 28.731C59.5273 28.504 59.4462 28.1974 59.4462 27.8097C59.4462 27.4942 59.514 27.2053 59.6511 26.9458C59.7882 26.6864 59.9976 26.4785 60.2806 26.3237C60.5636 26.1689 60.9145 26.0908 61.3331 26.0908C61.6265 26.0908 61.9125 26.1395 62.1926 26.2382C62.4727 26.337 62.7159 26.4726 62.9208 26.6451L62.738 27.1728C62.5228 27.0004 62.2943 26.8706 62.051 26.7837C61.8078 26.6967 61.5675 26.6539 61.3346 26.6539C60.8865 26.6539 60.5489 26.7601 60.3219 26.9709C60.0949 27.1832 59.9813 27.4559 59.9813 27.789C59.9813 28.0396 60.0433 28.2416 60.1686 28.3934C60.2924 28.5453 60.4486 28.6617 60.6373 28.7428C60.8245 28.8239 61.0766 28.9109 61.395 29.0067C61.771 29.1202 62.0746 29.2322 62.3061 29.3428C62.5375 29.4533 62.7336 29.6199 62.8943 29.844C63.0549 30.0681 63.136 30.3703 63.136 30.7521C63.136 31.069 63.0667 31.3565 62.9282 31.6159C62.7896 31.8754 62.5758 32.0817 62.2884 32.2336C62.0009 32.3854 61.6471 32.4621 61.2285 32.4621C60.8408 32.4635 60.4767 32.3884 60.1288 32.2395Z"
      fill={fill}
    />
  </svg>
)
