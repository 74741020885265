import * as React from "react";

function SvgTruck1(props) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.333 2.5H.833v10.833h12.5V2.5zM13.333 6.667h3.334l2.5 2.5v4.166h-5.834V6.667zM4.583 17.5a2.083 2.083 0 100-4.167 2.083 2.083 0 000 4.167zM15.417 17.5a2.083 2.083 0 100-4.167 2.083 2.083 0 000 4.167z"
        stroke="#212121"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgTruck1;
